import React, { FC, useReducer } from 'react';
import { Alert, Footer, Layout, Spinner } from '@legalshield/adonis-ux-framework';

import '../../../node_modules/@legalshield/products-questionnaire-lib/dist/esm/index.css';

import Router from '../Router/Router';
import { clearMessage } from './app.actionCreators';
import AppReducer, { appInitialState } from './app.reducer';
import AppContext from './AppContext';

export const App: FC = () => {
  const [state, dispatch] = useReducer(AppReducer, appInitialState),
    { loading, message } = state;

  const handleDismiss = (): void => {
    dispatch(clearMessage());
  };

  const content = (
    <Layout variant="fixed">
      {message ? (
        <Alert
          title={message.title}
          content={message.body}
          severity={message.success ? 'success' : 'error'}
          onDismiss={handleDismiss}
        />
      ) : (
        <></>
      )}
      <Router />
      <Footer align="center" htmlContent={footerHtml}></Footer>
    </Layout>
  );

  return (
    <AppContext.Provider value={{ state, appDispatch: dispatch }}>
      {loading && <Spinner spinnerSize="xlarge" blocking color="brand" />}
      {content}
    </AppContext.Provider>
  );
};
