import React, { FC } from 'react';
import { Container, Heading, Image, Text } from '@legalshield/adonis-ux-framework';

import { CardDisplayData } from '../requiredInfo.displayData';

const RequiredInfoCard: FC<CardDisplayData> = ({ icon, title, description }) => {
  return (
    <Container
      flexbox
      alignItems="center"
      background="light-gray"
      classNames={['p-4']}
      style={{ borderRadius: '0.5rem', flexBasis: 'calc(50% - 12px)' }}
    >
      <Container flexbox background="light-gray" classNames={['mr-4']}>
        <Image src={icon} />
      </Container>
      <div>
        <Container background="light-gray" classNames={['mb-3']}>
          <Heading as="T16" text={title} />
        </Container>
        <Text text={description} />
      </div>
    </Container>
  );
};

export default RequiredInfoCard;
