import { Questions } from '@legalshield/frontend-commons';

import { GetQuestionsError } from '../errors/GetQuestions.error';
import httpStatusCodeCheck from '../util/httpStatusCodeCheck';

const questionsApi = new Questions.Api();

const getQuestions = async (questionnaireId: string) => {
  try {
    const { data: questions } = await questionsApi.v1.publicProductsQuestionsGetSpecificPublicQuestionnaire(
      questionnaireId,
      {
        validateStatus: httpStatusCodeCheck,
      },
    );
    return questions;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new GetQuestionsError();
  }
};

export default getQuestions;
