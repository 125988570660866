import AppActionType from './app.actionType.enum';
import { AppReducerAction, AppState } from './app.interfaces';

export const appInitialState: AppState = {
  loading: false,
  message: null,
};

const AppReducer = (state: AppState, action: AppReducerAction): AppState => {
  const { SET_LOADING, SHOW_MESSAGE, CLEAR_MESSAGE } = AppActionType;

  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload.loading };
    case SHOW_MESSAGE:
      return {
        ...state,
        message: {
          createdAt: new Date().toISOString(),
          title: action.payload.title,
          body: action.payload.body,
          success: action.payload.success,
          error: action.payload.error,
        },
      };
    case CLEAR_MESSAGE:
      return { ...state, message: null };
    default:
      return state;
  }
};

export default AppReducer;
