import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import GetStarted from '../GetStarted/GetStarted';
import ReferralNetworkApplication from '../ReferralNetworkApplication/ReferralNetworkApplication';
import RequiredInfo from '../RequiredInfo/RequiredInfo';
import Success from '../Success/Success';

const Router: FC = () => {
  return (
    <Switch>
      <Route path="/questionnaire">
        <ReferralNetworkApplication />
      </Route>
      <Route path="/required_info">
        <RequiredInfo />
      </Route>
      <Route path="/success">
        <Success />
      </Route>
      <Route path="/*">
        <GetStarted />
      </Route>
    </Switch>
  );
};

export default Router;
