import { Answer } from '@legalshield/frontend-commons/dist/sdk/answers';
import { Answers } from '@legalshield/frontend-commons';
import { httpErrorHandler } from '../util/httpErrorHandler';
import httpStatusCodeCheck from '../util/httpStatusCodeCheck';
import { ReplaceAnswersError } from '../errors/ReplaceAnswers.error';

const userId = 'b6a1053b-fc01-4acc-9689-768609f4f2d9';

const answersApi = new Answers.Api();

const replaceAnswer = async (version: number, answerId: string, answer: Answer): Promise<Answer> => {
  try {
    const { data } = await answersApi.v1.publicAnswersReplaceAnswer(userId, answerId, answer, {
      withCredentials: true,
      validateStatus: httpStatusCodeCheck,
      headers: {
        'If-Match': version,
      },
    });
    return data as Answer;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    httpErrorHandler(e.response);
    throw new ReplaceAnswersError();
  }
};

export default replaceAnswer;
