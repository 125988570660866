import { TagManagerArgs } from '@legalshield/frontend-analytics';

enum LSEnvironment {
  Dev = 'dev',
  Uat = 'uat',
  Production = 'production',
  ProductionTest = 'productionTest',
}

const getEnvFromHostname = (): LSEnvironment => {
  const host = window.location.hostname;

  if (/.*test\..*/.test(host)) {
    return LSEnvironment.ProductionTest;
  }
  if (host.includes('dev-') || host.includes('localhost')) {
    return LSEnvironment.Dev;
  }
  if (host.includes('uat-')) {
    return LSEnvironment.Uat;
  }
  return LSEnvironment.Production;
};

export const getGtmVars = (): TagManagerArgs => {
  return {
    dev: {
      auth: '8ORNwoYVVPtSAsoW7UGgKw',
      gtmId: 'GTM-NQ3J2B7',
      preview: 'env-17',
    },
    local: {
      auth: '8ORNwoYVVPtSAsoW7UGgKw',
      gtmId: 'GTM-NQ3J2B7',
      preview: 'env-17',
    },
    uat: {
      auth: '5B6USoiYGGAjfirnUbMYCw',
      gtmId: 'GTM-NQ3J2B7',
      preview: 'env-21',
    },
    prod: {
      auth: 'g_gdq8mpXSg8PHfXMX3lQ',
      gtmId: 'GTM-NQ3J2B7',
      preview: 'env-1',
    },
  };
};

const getEnvironmentSecondLevelDomain = (isProviderUrl = true): string => {
  const host = window.location.hostname;
  const domain = isProviderUrl ? 'legalshieldproviders' : 'legalshield';

  if (host.includes('dev-')) {
    return `dev-${domain}.com`;
  }

  if (host.includes('uat-')) {
    return `uat-${domain}.com`;
  }

  return `${domain}.com`;
};

const appendTest = (): string => {
  const host = window.location.hostname;
  const subdomain = host.split('.')[0];
  return subdomain.includes('test') ? 'test' : '';
};

export default {
  LSEnvironment,
  getEnvFromHostname,
  getGtmVars,
  branchesServiceUrl: `https://branches.api.${getEnvironmentSecondLevelDomain()}`,
  featuresUrl: `http://features.${getEnvironmentSecondLevelDomain(false)}`,
  fileStorageServiceUrl: `https://imagestore.api.${getEnvironmentSecondLevelDomain()}`,
  firmsServiceUrl: `https://firms.api.${getEnvironmentSecondLevelDomain()}`,
  identitiesServiceUrl: `https://identities.api.${getEnvironmentSecondLevelDomain()}`,
  imageStoreUrl: `https://imagestore.api.${getEnvironmentSecondLevelDomain()}`,
  intakesUrl: `https://intakes${appendTest()}.${getEnvironmentSecondLevelDomain()}`,
  lawyersUrl: `https://lawyers${appendTest()}.${getEnvironmentSecondLevelDomain()}`,
  membersUrl: `https://members${appendTest()}.${getEnvironmentSecondLevelDomain()}`,
  organizationUrl: `https://organization${appendTest()}.${getEnvironmentSecondLevelDomain()}`,
  profilesServiceUrl: `https://profiles.api.${getEnvironmentSecondLevelDomain()}`,
  providersServiceUrl: `https://providers.api.${getEnvironmentSecondLevelDomain()}`,
  queriesServiceUrl: `https://queries.api.${getEnvironmentSecondLevelDomain()}`,
  questionsServiceUrl: `https://questions.api.${getEnvironmentSecondLevelDomain()}`,
  reportsUrl: `https://reports${appendTest()}.${getEnvironmentSecondLevelDomain()}`,
  resolutionsUrl: `https://resolutions${appendTest()}.${getEnvironmentSecondLevelDomain()}`,
  userSettingsKey: 'products-intakes-web',
  userSettingsServiceUrl: `https://user-settings.api.${getEnvironmentSecondLevelDomain()}`,
};
