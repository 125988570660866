import { LSError } from '../util/LSError.interface';

export class CreateAnswersError extends Error implements LSError {
  message: string;
  title: string;

  constructor() {
    super();
    this.title = 'Create Answers Error';
    this.message = 'There was an error verifying your answers, please contact support if the error persists';
  }
}
