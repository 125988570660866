import { S3UploadResponse } from '@legalshield/frontend-commons/src/sdk/imagestore';
import { FileWithPreview } from '@legalshield/products-questionnaire-lib';

import config from '../../config';
import { SubmitAnswersError } from '../errors/SubmitAnswers.error';
import { httpErrorHandler } from '../util/httpErrorHandler';

const bucketToEnv = [
  {
    env: 'dev',
    prefix: 'dev-',
    bucket: 'providers-documents-sandbox',
    imageServiceLocation: 'https://imagestore.api.dev-legalshieldproviders.com',
  },
  {
    env: 'uat',
    prefix: 'uat-',
    bucket: 'providers-documents-uat',
    imageServiceLocation: 'https://imagestore.api.uat-legalshieldproviders.com',
  },
  {
    env: 'prod',
    prefix: '',
    bucket: 'providers-documents-production',
    imageServiceLocation: 'https://imagestore.api.legalshieldproviders.com',
  },
];

const imageStoreServiceUrl = config.imageStoreUrl;
const bucketNameForEnv = bucketToEnv.find((entry) => imageStoreServiceUrl === entry.imageServiceLocation)?.bucket;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const findAttachments = (tasks: Record<string, any>): FileWithPreview[] => {
  return tasks.task14.attachments as FileWithPreview[];
};

export const uploadAttachments = async (dir: string, files?: File[]): Promise<S3UploadResponse[]> => {
  const formData = new FormData();
  files?.forEach((file) => {
    if (file) {
      if (file instanceof File) {
        formData.append('files', file, file.name);
      } else {
        formData.append('files', file);
      }
    }
  });

  const params = [
    ['app', 'providers'],
    ['dir', dir],
    ['locale', 'en-us'],
    ['isPublic', bucketNameForEnv ? 'false' : 'true'],
  ];

  if (bucketNameForEnv) {
    params.push(['bucketName', bucketNameForEnv]);
  }

  const searchParams = new URLSearchParams(params);
  const relativePath = `/v1/container/files?${searchParams.toString()}`;
  const url = imageStoreServiceUrl ? new URL(relativePath, imageStoreServiceUrl).toString() : relativePath;
  const resp = await fetch(url, {
    body: formData,
    credentials: 'include',
    method: 'POST',
  });

  if (!resp.ok) {
    httpErrorHandler(resp);
    throw new SubmitAnswersError();
  }

  return resp.json();
};
