import React, { FC } from 'react';
import { Divider } from '@legalshield/adonis-ux-framework';

import ContentLayout from '../shared/ContentLayout/ContentLayout';
import TitleAndDescription from '../shared/TitleAndDescription/TitleAndDescription';
import InfoCards from './InfoCards/InfoCards';
import { requiredInfoDisplayData } from './requiredInfo.displayData';

const TITLE = "What you'll need";
const DESCRIPTION = 'In order to complete this application, you will need the following:';

const RequiredInfo: FC = () => {
  return (
    <ContentLayout>
      <TitleAndDescription title={TITLE} as="T39" description={DESCRIPTION} />
      <Divider classNames={['my-9']} />
      <InfoCards cardDisplayData={requiredInfoDisplayData} />
    </ContentLayout>
  );
};

export default RequiredInfo;
