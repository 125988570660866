import React from 'react';
import { hydrate, render } from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { AnalyticServiceVendor, AnalyticsOptions, ThemisAnalytics } from '@legalshield/frontend-analytics';

import { App } from './components/App/App';
import envConfig from './config';

const root = document.getElementById('root');
let renderMethod;
if (root && root.innerHTML !== '') {
  renderMethod = hydrate;
} else {
  renderMethod = render;
}
const analyticsConfig: AnalyticsOptions = {
  client: AnalyticServiceVendor.GoogleTagManager,
  config: {
    gtmService: envConfig.getGtmVars(),
  },
};
const queryClient = new QueryClient();
const themisAnalytics = new ThemisAnalytics(analyticsConfig);
export default themisAnalytics;

themisAnalytics.initialize();
renderMethod(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
