import { Answers } from '@legalshield/frontend-commons';
import { Answer } from '@legalshield/frontend-commons/dist/sdk/answers';

import { CreateAnswersError } from '../errors/CreateAnswers.error';
import { httpErrorHandler } from '../util/httpErrorHandler';
import httpStatusCodeCheck from '../util/httpStatusCodeCheck';

const userId = 'b6a1053b-fc01-4acc-9689-768609f4f2d9';

const answersApi = new Answers.Api();
const createAnswers = async (answer: Answer): Promise<Answer> => {
  try {
    const { data } = await answersApi.v1.publicAnswersCreateAnswer(userId, answer, {
      withCredentials: true,
      validateStatus: httpStatusCodeCheck,
    });
    return data as Answer;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    httpErrorHandler(e.response);
    throw new CreateAnswersError();
  }
};

export default createAnswers;
