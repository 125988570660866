import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container, useViewportSize } from '@legalshield/adonis-ux-framework';

import { CardDisplayData } from '../requiredInfo.displayData';
import RequiredInfoCard from '../RequiredInfoCard/RequiredInfoCard';

interface InfoCardsProps {
  cardDisplayData: CardDisplayData[];
}
const InfoCards: FC<InfoCardsProps> = ({ cardDisplayData }) => {
  const history = useHistory();
  const viewportSize = useViewportSize();
  const isSmallScreen = viewportSize.width < 640;

  return (
    <>
      <Container
        flexbox
        flexDirection={`${isSmallScreen ? 'column' : 'row'}`}
        flexWrap={`${isSmallScreen ? 'nowrap' : 'wrap'}`}
        classNames={['mb-8']}
        style={{ rowGap: `${isSmallScreen ? '1rem' : '1.5rem'}`, columnGap: `${isSmallScreen ? '' : '1.5rem'}` }}
      >
        {cardDisplayData.map((item) => (
          <RequiredInfoCard
            key={`card-${item.title}`}
            icon={item.icon}
            title={item.title}
            description={item.description}
          />
        ))}
      </Container>
      <Container flexbox justifyContent="center">
        <Button
          stretch
          label="Back"
          long
          widthLong="12.5rem"
          iconLeft="nav_chevron_single_left"
          onClick={() => history.push('/')}
          classNames={['mr-5']}
        />
        <Button
          label="Get Started"
          long
          widthLong="12.5rem"
          iconRight="nav_chevron_single_right"
          onClick={() => history.push('/questionnaire')}
        />
      </Container>
    </>
  );
};

export default InfoCards;
