import React, { FC, ReactNode } from 'react';
import { Container, Grid, GridCol, GridRow, useViewportSize } from '@legalshield/adonis-ux-framework';

interface ContentLayoutProps {
  children: ReactNode;
  width?: string;
}
const ContentLayout: FC<ContentLayoutProps> = ({ children, width }) => {
  const viewportSize = useViewportSize();
  const isSmallScreen = viewportSize.width < 640;

  const styles = {
    width: `${isSmallScreen ? '90%' : width ? width : '60%'}`,
    margin: 'auto',
    maxWidth: '1680px',
  };

  return (
    <Grid>
      <GridRow variant="plain" classNames={['pt-6']}>
        <GridCol>
          <Container style={styles}>
            <>{children}</>
          </Container>
        </GridCol>
      </GridRow>
    </Grid>
  );
};

export default ContentLayout;
