import AdmissionDate from './AdmissionDate.svg';
import AreasofLaw from './AreasofLaw.svg';
import BarLicense from './BarLicense.svg';
import ProofofInsurance from './ProofofInsurance.svg';
import Rates from './Rates.svg';
import Success from './Success.svg';
import TaxID from './TaxID.svg';

const assets = {
  admissionDater: AdmissionDate,
  areasOfLaw: AreasofLaw,
  barLicense: BarLicense,
  proofOfInsurance: ProofofInsurance,
  rates: Rates,
  taxId: TaxID,
  success: Success,
};

export default assets;
