import AppActionType from './app.actionType.enum';
import { AppReducerAction } from './app.interfaces';

const { SET_LOADING, SHOW_MESSAGE, CLEAR_MESSAGE } = AppActionType;

export const setLoading = (isLoading: boolean): AppReducerAction => ({
  type: SET_LOADING,
  payload: { loading: isLoading },
});

export const showMessage = (title: string, message: string, success = false, error = false): AppReducerAction => ({
  type: SHOW_MESSAGE,
  payload: { title, body: message, success, error },
});

export const clearMessage = (): AppReducerAction => ({
  type: CLEAR_MESSAGE,
});
