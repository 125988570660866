import React, { createContext } from 'react';

import { AppReducerAction, AppState } from './app.interfaces';

interface IAppContext {
  state: AppState | null;
  appDispatch: React.Dispatch<AppReducerAction>;
}

const AppContext = createContext<IAppContext>({
  state: null,
  appDispatch: () => null,
});

export default AppContext;
