import assets from '../../assets';

export interface CardDisplayData {
  icon: string;
  title: string;
  description: string;
}

export const requiredInfoDisplayData: CardDisplayData[] = [
  {
    icon: assets.barLicense,
    title: 'Bar license number',
    description: 'State bar license number',
  },
  {
    icon: assets.proofOfInsurance,
    title: 'Proof of insurance',
    description: 'Malpractice coverage documents',
  },
  {
    icon: assets.areasOfLaw,
    title: 'Areas of law',
    description: 'Comprehensive list of services',
  },
  {
    icon: assets.admissionDater,
    title: 'Admission date',
    description: 'Practice law begin date',
  },
  {
    icon: assets.taxId,
    title: 'Tax ID',
    description: 'Taxpayer identification number',
  },
  {
    icon: assets.rates,
    title: 'Rates',
    description: 'Standard, fixed, and rate exceptions',
  },
];
