import React, { FC } from 'react';
import { Container, Image, useViewportSize } from '@legalshield/adonis-ux-framework';

import assets from '../../assets';
import ContentLayout from '../shared/ContentLayout/ContentLayout';
import TitleAndDescription from '../shared/TitleAndDescription/TitleAndDescription';

const TITLE = 'Thank you for your application!';
const DESCRIPTION =
  'Your application is under review. An email will be sent to you within 5 business days regarding the status of your application.';

const Success: FC = () => {
  const viewportSize = useViewportSize();
  const isSmallScreen = viewportSize.width < 640,
    isMediumScreen = viewportSize.width < 1024;

  return (
    <ContentLayout>
      <Container
        flexbox
        alignItems="center"
        justifyContent="center"
        flexDirection={`${isSmallScreen ? 'column' : 'row'}`}
      >
        <Container style={{ flexBasis: '50%' }}>
          <Container classNames={`${isMediumScreen ? '' : 'px-8'}`}>
            <TitleAndDescription title={TITLE} as="T28" description={DESCRIPTION} />
          </Container>
        </Container>
        <Container style={{ width: '300px' }}>
          <Image src={assets.success} />
        </Container>
      </Container>
    </ContentLayout>
  );
};

export default Success;
