interface IGetStartedDisplayData {
  howItWorks: {
    title: string;
    description?: string;
    info: { id: number; text: string }[];
  };
  qualifications: {
    title: string;
    description: string;
    info: { id: number; subtitle: string; description: string }[];
  };
}

const getStartedDisplayData: IGetStartedDisplayData = {
  howItWorks: {
    title: 'How it works',
    info: [
      {
        id: 1,
        text: 'LegalShield provides unlimited consultations and particular services to its subscription based members. Often this solves the immediate need but in many cases members will need further assistance.',
      },
      {
        id: 2,
        text: 'Members who are referred are directed to contact their assigned referral lawyer and together the two can decide to enter an attorney client relationship.',
      },
      {
        id: 3,
        text: 'For certain instances, the lawyer is paid by the firm who referred them. In other matters, the member engages the referral lawyer on a fee for service basis with the referral lawyer providing a 25% discount of their standard hourly rate.',
      },
    ],
  },
  qualifications: {
    title: 'Qualifications',
    description:
      'Before we get started, here are the qualifications required to join the LegalShield Lawyer Referral Network.',
    info: [
      {
        id: 4,
        subtitle: '2 years of experience',
        description: 'Must be in good standing with the bar or other regulatory body.',
      },
      {
        id: 5,
        subtitle: 'Equal justice for all',
        description: 'Must be dedicated to meet the needs of your clients. ',
      },
      {
        id: 6,
        subtitle: 'Insurance coverage',
        description: 'Must show proof of malpractice coverage of at least $100,000.',
      },
    ],
  },
};

export default getStartedDisplayData;
