import React, { FC } from 'react';
import { Container, Heading, Text } from '@legalshield/adonis-ux-framework';
import { HeadingAs } from '@legalshield/adonis-ux-framework';

interface TitleAndDescriptionProps {
  title: string;
  as: HeadingAs;
  description?: string;
  isSubtitle?: boolean;
}
const TitleAndDescription: FC<TitleAndDescriptionProps> = ({ title, as, description, isSubtitle = false }) => {
  return (
    <Container>
      <Container classNames={[`${isSubtitle ? 'mb-3' : 'mb-4'}`]}>
        <Heading as={`${as}`} text={title} />
      </Container>
      <Container classNames={[`${isSubtitle ? '' : 'mb-5'}`]}>
        <Text as="p" text={description} />
      </Container>
    </Container>
  );
};

export default TitleAndDescription;
