import { AxiosResponse } from 'axios';
import createHttpError, { BadRequest, HttpError, NotFound } from 'http-errors';

export const httpErrorHandler = (response: AxiosResponse | Response): HttpError => {
  let error: HttpError = createHttpError(response.status, 'An unexpected error has occurred');

  switch (response.status) {
    case 404:
      error = new NotFound('Something is not found');
      break;
    case 400:
      error = new BadRequest();
      break;
  }

  return error;
};
