import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container, Divider, Text } from '@legalshield/adonis-ux-framework';

import ContentLayout from '../shared/ContentLayout/ContentLayout';
import TitleAndDescription from '../shared/TitleAndDescription/TitleAndDescription';
import getStartedDisplayData from './getStarted.displayData';

const GetStarted: FC = () => {
  const { howItWorks, qualifications } = getStartedDisplayData;
  const history = useHistory();

  return (
    <ContentLayout>
      <TitleAndDescription title={howItWorks.title} as="T39" />
      <ul style={{ paddingLeft: '1.5rem' }}>
        {howItWorks.info.map((item) => (
          <li key={`item-${item.id}`} className="pb-3">
            <Text as="p" text={item.text} />
          </li>
        ))}
      </ul>
      <Divider classNames={['my-9']} />
      <TitleAndDescription title={qualifications.title} as="T31" description={qualifications.description} />
      <Container flexbox flexWrap="wrap" classNames={['mb-8']} style={{ rowGap: '1rem', columnGap: '1.5rem' }}>
        {qualifications.info.map((item) => (
          <Container key={`item-${item.id}`} style={{ flexBasis: 'calc(50% - 12px)' }}>
            <TitleAndDescription isSubtitle title={item.subtitle} description={item.description} as="T14" />
          </Container>
        ))}
      </Container>
      <Container flexbox justifyContent="center">
        <Button
          label="Next"
          long
          widthLong="12.5rem"
          iconRight="nav_chevron_single_right"
          onClick={() => history.push('/required_info')}
        />
      </Container>
    </ContentLayout>
  );
};

export default GetStarted;
